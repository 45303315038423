import React, { useEffect } from 'react'

import styled from 'styled-components'

const StyledRoot = styled.div`
  #video-compare-container {
    display: inline-block;
    line-height: 0;
    position: relative;
    width: 100%;
    height: 100vh;
  }
  #video-compare-container > video {
    width: 100%;
    position: absolute;
    top: 0;
    height: 100%;
    object-fit: cover;
  }
  #video-clipper {
    width: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: hidden;
  }
  #video-clipper video {
    width: 200%;
    position: absolute;
    height: 100%;
    object-fit: cover;
  }
`

const Videoslider = () => {
  useEffect(() => {
    function trackLocation(e) {
      var rect = videoContainer.getBoundingClientRect(),
        position = ((e.pageX - rect.left) / videoContainer.offsetWidth) * 100
      if (position <= 100) {
        videoClipper.style.width = position + '%'
        clippedVideo.style.width = (100 / position) * 100 + '%'
        clippedVideo.style.zIndex = 3
      }
    }
    var videoContainer = document.getElementById('video-compare-container'),
      videoClipper = document.getElementById('video-clipper'),
      clippedVideo = videoClipper.getElementsByTagName('video')[0]
    videoContainer.addEventListener('mousemove', trackLocation, false)
    videoContainer.addEventListener('touchstart', trackLocation, false)
    videoContainer.addEventListener('touchmove', trackLocation, false)
  })

  return (
    <StyledRoot>
      <div id="video-compare-container">
        <video loop autoPlay muted>
          <source
            src={require(`./../../assets/video/sample1_A.mp4`)}
            type="video/mp4"
          />
          <source
            src={require(`./../../assets/video/sample1_A.webm`)}
            type="video/webm"
          />
        </video>
        <div id="video-clipper">
          <video loop autoPlay muted>
            <source
              src={require(`./../../assets/video/sample1_B.mp4`)}
              type="video/mp4"
            />
            <source
              src={require(`./../../assets/video/sample1_B.webm`)}
              type="video/webm"
            />
          </video>
        </div>
      </div>
    </StyledRoot>
  )
}

export default Videoslider
