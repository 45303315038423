import React from 'react'
import Layout from '../../components/Demo/Layout'
import Videoslider from '../../components/Demo/Videoslider'

const VideoSliderPage = () => {
  const pageSlug = 'videoslider'

  return (
    <Layout pageSlug={pageSlug}>
      <Videoslider />
    </Layout>
  )
}

export default VideoSliderPage
